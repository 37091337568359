.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.navbar-mainbg {
  background-color: #5161ce;
  padding: 0px;
  border-radius: 15px;
  box-shadow: none;
}

#navbarSupportedContent {
    overflow: hidden;
    position: relative;
}

#navbarSupportedContent ul {
    padding: 0px;
    margin: 0px;
    display: flex; 
    margin-left: 4rem;
    cursor: pointer;
}

#navbarSupportedContent ul li a i {
    margin-left: 10px;
}

#navbarSupportedContent li {
    list-style-type: none;
}

#navbarSupportedContent ul li a {
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
    font-size: 15px;
    display: block;
    padding: 20px 20px;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    position: relative;
}

#navbarSupportedContent > ul > li.active > a {
    color: #5161ce;
    background-color: transparent;
    transition: all 0.7s;
}

#navbarSupportedContent a:not(:only-child):after {
    content: "\f105";
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 14px;
    font-family: "Font Awesome 5 Free";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;
    transition: 0.5s;
}

#navbarSupportedContent .active > a:not(:only-child):after {
    transform: rotate(90deg);
}

.hori-selector {
    display: inline-block;
    position: absolute;
    height: 100%;
    top: 0px;
    left: 0px;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    background-color: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-top: 10px;
}
.hori-selector .right,
.hori-selector .left {
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: #fff;
    bottom: 10px;
}
.hori-selector .right {
    right: -25px;
}
.hori-selector .left {
    left: -25px;
}
.hori-selector .right:before,
.hori-selector .left:before {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #5161ce;
}
.hori-selector .right:before {
    bottom: 0;
    right: -25px;
}
.hori-selector .left:before {
    bottom: 0;
    left: -25px;
}

@media (min-width: 992px) {
    .navbar-expand-custom {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .navbar-expand-custom .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .navbar-expand-custom .navbar-toggler {
        display: none;
    }
    .navbar-expand-custom .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

@media (max-width: 991px) {
    #navbarSupportedContent ul li a {
        padding: 12px 30px;
    }
    .hori-selector {
        margin-top: 0px;
        margin-left: 10px;
        border-radius: 0;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
    }
    .hori-selector .left,
    .hori-selector .right {
        right: 10px;
    }
    .hori-selector .left {
        top: -25px;
        left: auto;
    }
    .hori-selector .right {
        bottom: -25px;
    }
    .hori-selector .left:before {
        left: -25px;
        top: -25px;
    }
    .hori-selector .right:before {
        bottom: -25px;
        left: -25px;
    }
}

@media (max-width: 991px) {
    #navbarSupportedContent ul li a {
        padding: 12px 30px;
    }
    .hori-selector {
        margin-top: 0px;
        margin-left: 10px;
        border-radius: 0;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
    }
    .hori-selector .left,
    .hori-selector .right {
        right: 10px;
    }
    .hori-selector .left {
        top: -25px;
        left: auto;
    }
    .hori-selector .right {
        bottom: -25px;
    }
    .hori-selector .left:before {
        left: -25px;
        top: -25px;
    }
    .hori-selector .right:before {
        bottom: -25px;
        left: -25px;
    }
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  margin: 0 0 0 5px; 
  width: 15px;
  height: 15px;
  
  & .path {
    stroke: #fff;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  
}

.spinner.black{
  & .path {
    stroke: #000;
  }
  
}

.spinner-only {
  animation: rotate 2s linear infinite;
  z-index: 2;
  margin: 0 0 0 5px; 
  width: 40px;
  height: 40px;
  
  & .path {
    stroke: #fff;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  
}

.spinner-only.black{
  & .path {
    stroke: #000;
  }
  
}

.spinner-only.secondary{
  & .path {
    stroke: #d3d3d3;
  }
}

.under-line{
  border-bottom: 1px solid #edebeb;
}

.search-container {
  margin-bottom: 1rem ;
  margin-top: 1rem ;
  display: flex;
  justify-content: flex-end;
}

.container-top {
  margin-bottom: 1rem ;
  margin-top: 1rem ;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-top .back{
  cursor: pointer;
  color: #00A2AD;
} 

.search-container input {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 250px;
}

.search-container select {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 250px;
}


.text-size-24{
  font-size: 24pt;
}.text-size-20{
  font-size: 20pt;
}.text-size-18{
    font-size: 18pt;
}.text-size-16{
  font-size: 16pt;
}.text-size-14{
  font-size: 14pt;
}.text-size-12{
  font-size: 12pt;
}.text-size-10{
  font-size: 10pt;
}.text-size-9{      
  font-size: 9pt;
}.text-size-8{
  font-size: 8pt;
}.text-size-7{
  font-size: 7pt;
}.text-size-6{
  font-size: 6pt;
}.text-size-5{
  font-size: 5pt;
}.text-size-4{
  font-size: 4pt;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.pagination {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
}

.pagination li {
  display: inline;
  margin: 0 5px;
}

.pagination li a {
  text-decoration: none;
  color: #007bff;
  padding: 5px 10px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

.pagination li a:hover {
  background-color: #e9ecef;
}

.pagination li.active a {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
}

.qr-reader-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  height: 400px;
  margin: 0 auto;
  background-color: #000;
}

.qr-reader {
  width: 100%;
  height: 100%;
}

.qr-reader video {
  object-fit: cover; 
}

.scanner-box {
  position: absolute;
  top: 0;
  left: 0;
  margin: 5rem;
  width: calc(100% - 10rem);
  height: calc(100% - 10rem);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
}

.scanner-box2 {
  width: 100%;
  height: 100%;
}

.scanner-box2::before {
  content: "";
  width: 25px;
  height: 25px;
  border: 4px solid #ffffff;
  border-top-color: transparent;
  border-right-color: transparent;
  position: absolute;
  left: 0;
  bottom: 0;
}

.scanner-box2::after {
  content: "";
  width: 25px;
  height: 25px;
  border: 4px solid #ffffff;
  border-top-color: transparent;
  border-left-color: transparent;
  position: absolute;
  right: 0;
  bottom: 0;
}

.scanner-box::before {
  content: "";
  width: 25px;
  height: 25px;
  border: 4px solid #ffffff;
  border-bottom-color: transparent;
  border-right-color: transparent;
  position: absolute;
  left: 0;
  top: 0;
}

.scanner-box::after {
  content: "";
  width: 25px;
  height: 25px;
  border: 4px solid #ffffff;
  border-bottom-color: transparent;
  border-left-color: transparent;
  position: absolute;
  right: 0;
  top: 0;
}

.scanner-box::before,
.scanner-box::after,
.scanner-box2::before,
.scanner-box2::after {
  animation: blink 0.7s infinite;
  z-index: 10;
}


.scanner-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgba(77, 77, 255, 0.5);
  animation: scan-animation 2s infinite;
}

.scanner-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 255, 0),  rgba(77, 77, 255, 0.5));
  animation: shadow-animation 2s infinite; 
  z-index: 1;
  pointer-events: none;
}


/* modal */
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.8rem;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  transition: all 60ms ease-in-out;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  appearance: none;
  line-height: 1.3;
  font-weight: 500;
  text-transform: capitalize;
  color: #4a4a4a;
  background-color: #f2f2f2;
  border: 0 none;
  border-radius: 3px;
}

.button:hover {
  opacity: 0.85;
}

.button:active {
  transform: scale(0.97);
  opacity: 0.75;
}

.button.good {
  color: #fff;
  background: #47b8e0;
}

.button.danger {
  color: #fff;
  background: #e04747;
}

.button.secondary {
  color: #fff;
  background: #515151;
}

/* Modal styles */
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.8rem;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  transition: all 60ms ease-in-out;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  appearance: none;
  line-height: 1.3;
  font-weight: 500;
  text-transform: capitalize;
  color: #4a4a4a;
  background-color: #f2f2f2;
  border: 0 none;
  border-radius: 3px;
}

.button:hover {
  opacity: 0.85;
}

.button:active {
  transform: scale(0.97);
  opacity: 0.75;
}

.button.good {
  color: #fff;
  background: #47b8e0;
}
/* Modal styles */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 30px;
  left: 90px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.modal-content {
  background-color: #fff;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(99, 106, 119, 0.6);
  max-width: 500px;
  width: 100%;
  visibility: visible !important;
  animation-name: slideDown;
  animation-duration: 0.6s;
  animation-timing-function: ease;
  overflow: hidden;
}

.modal.closing .modal-content {
  animation-name: slideUp;
  animation-duration: 0.6s;
  animation-timing-function: ease;
}

.cropper-container {
  position: relative;
  width: 100%;
  height: 500px;
  margin-bottom: 1rem;
  z-index: 1; 
}

.crop-button-container {
  position: relative;
  z-index: 2; 
  top: 1rem;
  right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.crop-button-container .crop{
  height: 50px;
  width: 50px;
  background: none;
  border: none;
  cursor: pointer;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  background-color: #a9a9a969;
  border-radius: 50%;
}

.crop-button-container .cancel{
  height: 50px;
  width: 50px;
  background: none;
  border: none;
  cursor: pointer;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  background-color: #ff000069;
  border-radius: 50%;
}

.avatar-preview-container {
  position: relative;
  display: inline-block;
}

.avatar-preview {
  display: block;
  max-width: 100%;
  max-height: 200px;
  margin-top: 1rem;
  border-radius: 5px;
}

.cancel-avatar {
  position: absolute;
  top: 22px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  background-color: #00000069;
  border-radius: 50%;
}


/* Scan result styles */
.scan-result {
  text-align: left;
  padding: 20px;
  border: 2px solid #ddd;
  border-radius: 8px;
  margin-top: 20px;
}

.response-details {
  margin-top: 20px;
}

.response-details h3, .response-details h4 {
  margin-bottom: 10px;
}

.response-details p {
  margin: 5px 0;
}

.detail-item {
  display: flex;
  align-items: center;
  margin-top: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #f9f9f9;
}

.detail-item img {
  display: block;
  border-radius: 8px;
}

.scan-result button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  background: #007bff;
  color: white;
  cursor: pointer;
}

.scan-result button:hover {
  background: #0056b3;
}


.classLoadingidetail{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.cover-detail-content {
  display: flex;
  box-sizing: border-box;
  width: 100%; 
}

.detail-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.detail-content .detail-content-row {
  display: flex;
  margin-top: 1rem;
}

.detail-content .detail-content-row-wisata {
  display: flex;
  margin-top: 1rem;
}

.detail-content .detail-content-row img {
  margin-left: 10px;
  border-radius: 10px;
  width: 100%; 
  max-width: 400px; 
  height: auto; 
}

.detail-content .detail-content-row-wisata img {
  margin-left: 10px;
  border-radius: 10px;
  width: 100%; 
  max-width: 200px; 
  height: auto; 
}

.detail-content .detail-content-row .title-name {
  padding-right: 1rem;
  font-weight: 800;
  width: 250px;
}

.detail-content .detail-content-row .subtitle-name {
  padding-left: 10px;
  min-width: 400px;
}

.detail-content .detail-content-row-wisata .title-name {
  padding-right: 1rem;
  font-weight: 800;
  width: 250px;
}

.detail-content .detail-content-row-wisata .subtitle-name {
  padding-left: 7px;
  min-width: 400px;
}

.detail-title-name span, .titik-dua span, .detail-subtitle-name span {
  margin-top: 1rem;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(4%);
  }
  65% {
    transform: translateY(-2%);
  }
  80% {
    transform: translateY(2%);
  }
  95% {
    transform: translateY(-1%);
  }
  100% {
    transform: translateY(0%);
  }
}

/* Slide up animation */
@keyframes slideUp {
  0% {
    transform: translateY(0%);
  }
  30% {
    transform: translateY(2%);
  }
  35% {
    transform: translateY(-2%);
  }
  50% {
    transform: translateY(4%);
  }
  65% {
    transform: translateY(-80%);
  }
  100% {
    transform: translateY(-200%);
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #fff;
  padding: 1rem;
  z-index: 2;
  border-bottom: 1px solid #ddd;
}

.modal-header .close {
  cursor: pointer;
}

.modal-body {
  max-height: 400px;
  overflow-y: auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #ddd;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-group select,
.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

@keyframes scan-animation {
  0% {
    top: 0;
  }
  100% {
    top: 100%;
  }
}

@keyframes shadow-animation {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@media (max-width: 768px) {
  .qr-reader-container {
    max-width: 100%; 
    height: 320px; 
    background-color: #000;
    margin-bottom: 2rem;
  }

  .scanner-box {
    margin: 4rem;
    width: calc(100% - 8rem);
    height: calc(100% - 8rem);
  }
}


@media (max-width: 440px) {
  .qr-reader-container {
    max-width: 100%; 
    height: 320px; 
    margin-bottom: 2rem;
    background-color: #000;
  }

  .modal {
    top: 10px;
    left: 0;
  }

  .modal-content {
    margin: 0rem 1rem;
  }

  .modal-body {
    max-height: 500px;
  }
}
